<template>
  <ws-button :class="$style.pollBtn" lg color="primary" outlined v-on="$listeners">
    <slot></slot>
  </ws-button>
</template>
<script>
import WsButton from "@/components/base/WsButton";

export default {
  name: "PollControlButton",
  components: {
    WsButton,
  },
};
</script>
<style lang="scss" module>
.pollBtn {
  width: 169px;
  min-width: auto;
  font-weight: 600;
}
</style>
