import axios from "axios";
import config from "@/settings/config";

export default function(data) {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${config.serverUrl}/web/api/getOmnidesk`,
      headers: {
        "content-type": "multipart/form-data"
      },
      data: data
    }).then(
      resp => {
        resolve(resp);
      },
      error => {
        reject(error);
      }
    );
  });
}
