<template>
  <div class="support-form">
    <!--<div class="response-fader" v-if="response"></div>-->
    <div v-if="message" class="response-message">{{ message }}</div>
    <loader v-if="pending" />
    <form v-else enctype="multipart/form-data" @submit.prevent="sendRequest">
      <div v-if="!userName" class="form-group">
        <label>
          {{ $t("techSupport.fullName") }}
          <input v-model="fio" name="field_user_full_name" type="text" />
        </label>
      </div>
      <div v-if="!userMail" class="form-group">
        <label>
          {{ $t("techSupport.email") }}
          <input v-model="email" name="field_user_email" type="email" />
        </label>
      </div>
      <div class="form-group">
        <label>
          {{ $t("techSupport.subject") }}
          <input v-model="subject" name="field_subject" type="text" />
        </label>
      </div>
      <div class="form-group">
        <label>
          {{ $t("techSupport.content") }}
          <textarea v-model="content" name="field_message" />
        </label>
      </div>
      <div class="bottom-block">
        <label class="file-upload-label">
          <span class="icon"></span>
          <span>{{ filesCountMessage }}</span>
          <input
            type="file"
            accept="image/*"
            multiple
            @change="filesChange($event.target.files)"
          />
        </label>
        <poll-control-button :disabled="pending" type="submit">
          {{ $t("techSupport.send") }}
        </poll-control-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import SupportService from "../../services/SupportService";
import PollControlButton from "@/components/common/polls/components/common/PollControlButton";
import { i18nPlurals } from "@/utils";
import { room } from "@/store/modules/store.namespaces";
import { CLOSE_SUPPORT_MODAL } from "@/store/mutations/mutation-types";
import Loader from "@/components/common/elements/Loader";

let formData = new FormData();

export default {
  name: "SupportForm",
  components: { Loader, PollControlButton },
  props: ["userName", "userMail"],
  data() {
    return {
      pending: false,
      fio: "",
      email: "",
      subject: "",
      content: "",
      message: "",
      response: null,
      attachmentsCount: 0,
    };
  },
  computed: {
    ...mapState(room, {
      locale: "interfaceLanguage",
    }),
    filesCountMessage() {
      return this.attachmentsCount
        ? `${this.attachmentsCount} ${i18nPlurals("file", this.attachmentsCount, this.locale)}`
        : this.$t("techSupport.addFiles");
    },
  },
  watch: {
    fio() {
      this.message = "";
    },
    email() {
      this.message = "";
    },
    subject() {
      this.message = "";
    },
    content() {
      this.message = "";
    },
  },
  destroyed() {
    this.fio = "";
    this.email = "";
    this.subject = "";
    this.content = "";
    this.message = "";
    this.response = null;
    this.attachmentsCount = 0;
    formData = new FormData();
  },
  methods: {
    ...mapMutations({
      closeSupportModal: CLOSE_SUPPORT_MODAL,
    }),
    filesChange(files) {
      this.attachmentsCount = 0;
      formData = new FormData();
      if (!files || files.length === 0) return;
      for (let i = 0, l = files.length; i < l; i++) {
        formData.append(`attachments[${i}]`, files[i], files[i].name);
        this.attachmentsCount++;
      }
    },
    async sendRequest() {
      if (
        (this.fio || this.userName) &&
        (this.email || this.userMail) &&
        this.subject &&
        this.content
      ) {
        formData.append("user_email", this.email || this.userMail);
        formData.append("user_full_name", this.fio || this.userName);
        formData.append("subject", this.subject);
        formData.append("content", this.content);
        formData.append("language_id", 1);
        try {
          this.pending = true;
          const { data } = await SupportService(formData);
          if (data.case.status === "open") {
            this.closeSupportModal();
            this.showNotifyStatus(true);
          } else {
            throw "Error";
          }
        } catch {
          this.showNotifyStatus(false);
        } finally {
          this.pending = false;
        }
      } else {
        this.message = this.$t("techSupport.allFieldsShouldBeForfilled");
      }
    },
    showNotifyStatus(success) {
      const type = success ? "success" : "error";
      const text = success
        ? this.$t("techSupport.appealAccepted")
        : this.$t("techSupport.appealNotAccepted");
      const notification = { group: "support", type, text };
      this.$notify(notification);
    },
  },
};
</script>

<style scoped lang="less">
.support-form {
  display: flex;
  flex-direction: column;
  position: relative;
  .response-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
  }
  .response-message {
    position: absolute;
    top: -20px;
    font-size: 14px;
    color: var(--negative-color);
  }
  .form-group {
    padding: 5px 0;
    label {
      width: 100%;
      margin-bottom: 5px;
    }
    input,
    textarea {
      width: 100%;
      border: 1px solid var(--base-color);
      border-radius: 3px;
      padding: 5px;
      max-width: 100%;
    }
    textarea {
      min-height: 100px;
      font-size: 16px;
      min-width: 100%;
    }
  }
  .bottom-block {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .file-upload-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    .icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/images/icons/clip.svg") no-repeat center
        center;
      background-size: contain;
      margin-right: 10px;
    }
    input {
      opacity: 0;
      position: absolute;
      width: 1px;
      height: 1px;
      cursor: default;
      text-indent: -9999px;
    }
  }
}
</style>
